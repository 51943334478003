<div
  class="max-w-screen-sm mx-auto py-8 px-4 md:px-8 min-h-screen"
  *ngIf="!this.registed"
>
  <form [formGroup]="form" class="ea-form">
    <fieldset>
      <div class="flex flex-col gap-4">
        <legend>
          <h1 class="text-2xl font-bold font-title mb-4">Reset Email</h1>

          <p class="text-base">Please enter your current email and New Email</p>
        </legend>
        <div
          class="p-4 lg:p-8 bg-theme-surface text-theme-on-surface border border-gray-300 rounded"
        >
          <div class="ea-form-field">
            <label for="old-email-input" class="ea-label required"
              >Current Email</label
            >
            <input
              id="old-email-input"
              formControlName="oldemail"
              name="oldemail"
              class="ea-input"
              type="email"
              placeholder="Email address"
              autocomplete="email"
              style="background-color: beige"
              alt="old email input"
            />
          </div>
        </div>
        <div
          class="p-4 lg:p-8 bg-theme-surface text-theme-on-surface border border-gray-300 rounded"
        >
          <div class="ea-form-field">
            <label for="new-email-input" class="ea-label required">New Email</label>
            <input
              id="new-email-input"
              formControlName="email"
              name="email"
              class="ea-input"
              type="email"
              placeholder="Email address"
              autocomplete="email"
              alt="new email input"
            />
          </div>
        </div>

        <input
          type="submit"
          class="ea-button ea-button-primary w-full"
          value="Submit"
          title="Submit"
          [disabled]="this.busy"
          (click)="submit()"
          alt="submit"
        />
      </div>
    </fieldset>
  </form>
</div>
<div
  class="max-w-screen-sm mx-auto py-8 px-4 md:px-8 min-h-screen"
  *ngIf="this.registed"
>
  <form class="ea-form">
    <fieldset>
      <div class="ea-form">
        <div class="flex flex-col gap-4">
          <div
            class="p-4 lg:p-8 bg-theme-surface text-theme-on-surface border border-gray-300 rounded"
            style="text-align: center"
          >
            <h1 class="text-1xl font-bold font-title mb-4">
              An email has been sent to your current email address. Please
              verify your change request
            </h1>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
